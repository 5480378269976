import Graph from './Graph'
import GetStakeholders from '../../Stakeholder/GetStakeholders'
import React, { useState, useEffect } from 'react';
import { getStakeholderData, getVariableScoresIfAnswered, getVariablesForStakeHolders } from '../../Tools';
import Bar from "../Nivo/Bar"
const MainNiveles = (props) => {
    const [stakeHolders, setStakeHolders] = useState([])
    const [updateStakeHolders, setUpdateStakeHolders] = useState(0)

    const [onlyCollector, setOnlyCollector] = useState([])

    const [data, setData] = useState([
      
    ])

    

    useEffect(() => {
       console.log("Pilares stakeholders are being updated", stakeHolders)
       var temp = []
       for(var i=0;i<stakeHolders.length;i++){
           temp.push(stakeHolders[i].collectorName)
       }
       setOnlyCollector(temp)
       
        
      }, [ stakeHolders, updateStakeHolders]);

      //This is when the user click
      async function generatePilares(){
          console.log("Generating Pilares")


          //Returns the stakeHolder array + a data attribute with all the submissions
          var allData = await getStakeholderData(stakeHolders, props.serverAddress, props.token)


          var finalData = await getVariablesForStakeHolders(allData)
          console.log("Final Data Pilares", finalData)


          //For each stakeholder, loop throu          
          var processedData = [{
              variable : 'Pilar 1'
          },
            {variable : 'Pilar 2'} ,
            {variable : 'Pilar 3'},
            {variable : 'Pilar 4'}   
        ]
   

          for(var i =0;i<finalData.length;i++){
           


              //First we add all the variables that are already in the list
              for(var y =0;y<processedData.length;y++){
                if(finalData[i].variables[processedData[y].variable]){
                    processedData[y][finalData[i].collectorName] = finalData[i].variables[processedData[y].variable]/finalData[i].max[processedData[y].variable]

                    //Get the selected weight for this stakeholder
                    var weight = 0
                    for(var x = 0;x<stakeHolders.length;x++){
                        if(stakeHolders.collector == finalData[i].collectorName){
                            weight = parseFloat(stakeHolders.weight)
                        }
                    }

                    processedData[y]['Aggregate'] += (finalData[i].variables[processedData[y].variable]/finalData[i].max[processedData[y].variable]) * weight

                    



                }
                }

            



          }


          console.log("Final data Pilares", processedData)
          setData(processedData)
          /* 
          variables we are using
          */
        

      }
    
  return (
<div className='text-center'>


    <GetStakeholders {...props} 
    stakeHolders = {stakeHolders} 
    setStakeHolders={setStakeHolders}
    updateStakeHolders={updateStakeHolders}
    setUpdateStakeHolders={setUpdateStakeHolders}
    />
    <a className='btn btn-success' onClick={()=>{generatePilares()}}>Generate Pilares</a>

    <br />

    <Bar data={data} indexBy={'variable'} keys={onlyCollector}/>
    </div>





  )
}

export default MainNiveles