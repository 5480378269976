import {weightedAverage} from '../../Tools'
export const generateMatrixCoordinates= async function(matrixStakeHolders){

    var x_array = []
    var y_array = []
    var name_array = []

    var runningWeightedTotalX = 0
    var runningWeightedTotalY = 0
    var runningPercent = 0

    
    for(var i = 0;i<matrixStakeHolders.length;i++){
        var thisStakeholder = matrixStakeHolders[i]

        //if this collector has data
        if(thisStakeholder.data){
            //Calculate coordinates
            /*
            var Y = parseFloat(thisStakeholder.variables['P']) + parseFloat(thisStakeholder.variables['A'])
            var X = parseFloat(thisStakeholder.variables['N']) + parseFloat(thisStakeholder.variables['E'])
            var x_new = 0.5*X + 0.5*Y+0.5
            var y_new = -0.5*X + 0.5*Y+0.5
            */
           console.log("MATRIX VARS",thisStakeholder.variables['PANE-X'])
           console.log("What",thisStakeholder.variables['PANE-Y'])
            const x_new = thisStakeholder.variables['PANE-X']/thisStakeholder.appeared['PANE-X']
            const y_new = thisStakeholder.variables['PANE-Y']/thisStakeholder.appeared['PANE-Y']
            console.log("MATRIX ENW", y_new, x_new)

            if(y_new != undefined && x_new != undefined){
              console.log("MATRIX ENW", y_new, x_new)

                runningWeightedTotalX += x_new * parseFloat(thisStakeholder.weight)
                runningWeightedTotalY += y_new * parseFloat(thisStakeholder.weight)
                runningPercent += parseFloat(thisStakeholder.weight)

            
                x_array.push(x_new)
                y_array.push(y_new)
                name_array.push(thisStakeholder.collectorName)
               


        }

      

    }
      

    }
    x_array.push(runningWeightedTotalX/runningPercent)
    y_array.push(runningWeightedTotalY/runningPercent)
    name_array.push('wavg')

    //Now we get the weighted average

    return {x : x_array, y : y_array, name : name_array}
}

export const getMatrix= async function(coordinates){

    return fetch('https://matrix.epi.hackerslab.dev/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
 
     
      },
      body : JSON.stringify({X: coordinates.x, Y: coordinates.y, names: coordinates.name})
    })
      .then(data => data.json())
   }
