import Graph from './Graph'
import GetStakeholders from '../../Stakeholder/GetStakeholders'
import React, { useState, useEffect } from 'react';
import { getStakeholderData, getVariableScoresIfAnswered, getVariablesForStakeHolders } from '../../Tools';
//When done with stakeholders then add the graph
//    <Graph avgVar={props.avgVar} serverAddress="http://127.0.0.1:5000" allCollectors={props.allCollectors}/>
import {generateMatrixCoordinates, getMatrix} from './MatrixTools'
const MainMatrix = (props) => {
    const [matrixStakeholders, setMatrixStakeHolders] = useState([])
    const [matrixSrc, setMatrixSrc] = useState('')
    const [updateStakeHolders, setUpdateStakeHolders] = useState(0)

    

    useEffect(() => {
       console.log("Matrix stakeholders are being updated", matrixStakeholders)
        
      }, [ matrixStakeholders]);

      //This is when the user click
      async function generateMatrix(){
          console.log("Generating matrix")

          //Returns the stakeHolder array + a data attribute with all the submissions
          var allData = await getStakeholderData(matrixStakeholders, props.serverAddress, props.token)
          console.log("alldata", allData)


          var finalData = await getVariablesForStakeHolders(allData)
          var coordinates = await generateMatrixCoordinates(finalData)

          const dataFromServer = await getMatrix(coordinates);
          if(dataFromServer.status === 200){
              setMatrixSrc('https://matrix.epi.hackerslab.dev/static/'+dataFromServer.file)

          }
          console.log("Coordinated", coordinates)

      }
    
  return (
<div className='text-center'>


    <GetStakeholders {...props} 
    stakeHolders = {matrixStakeholders} 
    setStakeHolders={setMatrixStakeHolders}
    updateStakeHolders={updateStakeHolders}
    setUpdateStakeHolders={setUpdateStakeHolders}
    />
    <a className='btn btn-success' onClick={()=>{generateMatrix()}}>Generate Matrix</a>

    <br />
    <img src={matrixSrc} width="500px" height="auto"/>
    </div>





  )
}

export default MainMatrix