import logo from './logo.svg';
import './App.css';
import { Routes, Route, Link } from "react-router-dom";
import Login from './Containers/Login/Login';
import useToken from './Components/useToken';
import Surveys from './Containers/Surveys/Surveys';
import { Header } from './Components/Header/Header';
import Survey from './Containers/Survey/Survey';
import Home from './Containers/Home/Home';
function App() {

  const serverAddress="https://backend.opensurvey.ml"
  const { token, setToken } = useToken();

  async function logout() {
    setToken({token : undefined})
   }


  if(!token){
    return <div>
      <Login token={token} setToken={setToken} serverAddress={serverAddress}/> 
    </div>
  }
  return (
    <div className="App">
     <Header logout={logout}/>
     <br />
     <Routes>
        <Route exact path="/" element={<Home logo={logo} />} />
        <Route path ="/survey/:id" element={<Survey serverAddress={serverAddress} token={token}/>} />
        <Route path="/surveys" element={<Surveys serverAddress={serverAddress} token={token}/>} />
        <Route element={<h1>Page not found</h1>} />
      </Routes>
    </div>
  );
}

export default App;
