import React, { useState, useEffect } from 'react';
import {Link, useParams } from "react-router-dom";
import MainMatrix from './Graphs/Matrix/MainMatrix';
import MainPilares from './Graphs/Pilares/MainPilares';

import MainNiveles from './Graphs/Niveles/MainNiveles';
import processSubmissions from './Process';
import GetStakeholders from './Stakeholder/GetStakeholders';
import {getPen, getQuestionAnswers } from './Tools';
const Survey = (props) => {
    const [submissions, setSubmissions] = useState([])
    const [allCollectors, setAllCollectors] = useState({})
    const [selectedGraph, setSelectedGraph] = useState('')

    const [collectors, setCollectors] = useState({})

    const [survey, setSurvey] = useState({})

    const [avgVar, setAvgVal] = useState({})
    const { id } = useParams();
    //Get submissions

    const [surveys, setSurveys] = useState([])
    //Get surveys
    async function getSurveys() {
        return fetch(props.serverAddress+'/admin/surveys', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'token' :props.token
          }
        })
          .then(data => data.json())
       }
    //Getting inital
    useEffect(() => {
        async function runGet() {
            const dataFromServer = await getSurveys();
            if(dataFromServer.status == 200){
                setSurveys(dataFromServer.data);
        
            }
        }
       runGet()
        
      }, [ ]);

    
    var groupBy = function(xs, key){
      return( xs.reduce(function(rv,x){
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
      },{})

      )
    }
    async function getSubmissions() {
        return fetch(props.serverAddress+'/admin/submissions', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'token' : props.token
         
          },
          body: JSON.stringify({
            'survey' : id
          })
        })
          .then(data => data.json())
       }
       async function getSurvey() {
        return fetch(props.serverAddress+'/survey/get', {
          method: 'get',
          headers: {
            'Content-Type': 'application/json',
            'surveyid' : id
         
          },
          
        })
          .then(data => data.json())
       }
       useEffect(() => {
        


        async function runGet() {
            const dataFromServer = await getSubmissions();
            if(dataFromServer.status === 200){
                setSubmissions(dataFromServer.data);
               
            }
            const sureveyfromServer = await getSurvey();
            if(sureveyfromServer.status === 200){
                setSurvey(sureveyfromServer.data);
                var collectors = sureveyfromServer.data.collectors
                var collectorsNow = {}
                //Need to match collectors to their names
                for(var h=0;h<collectors.length;h++){
                  collectorsNow[collectors[h].id] = collectors[h].name

                }
                setCollectors(collectorsNow)
               
            }
        }
       runGet()
        
      }, [  ]);

      useEffect(() => {
        async function runGet() {

        if(Object.keys(collectors).length > 0 && submissions.length >0){

          /*
          This gets penalization based on which option, not which variable and how they were affected
          var questionAnswers = await getQuestionAnswers(submissions)
          var pensalization = await getPen(questionAnswers,1,1.2,1.5,1.8,2)
          */
          //const hi = await processSubmissions(submissions, pensalization)

          //Lets get the value of each variable
          //We do this by averaging
          
          //setAvgVal(hi)
         var hi = 'b'
         
        }
      }
      runGet()
      }, [submissions, collectors ]);

   


      // useEffect(() => {

      //   async function runGet() {

      //   if(Object.keys(collectors).length > 0 && submissions.length >0){
      //       //For each collector filter the results and save that
      //       var groupByCollector = groupBy(submissions,'collector')
      //       var newColectors = {}
      //       for(var j=0;j<Object.keys(groupByCollector).length;j++){
      //           var thisCollectorquestionAnswers = await getQuestionAnswers(groupByCollector[Object.keys(groupByCollector)[j]])
      //           var thisCollectorPen = await getPen(thisCollectorquestionAnswers,1,1.2,1.5,1.8,2)
        
      //           if(collectors[Object.keys(groupByCollector)[j]]){
      //             newColectors[collectors[Object.keys(groupByCollector)[j]]] = await processSubmissions(groupByCollector[Object.keys(groupByCollector)[j]], thisCollectorPen)
        
      //           }
        
      //       }
      //       setAllCollectors(newColectors)
      
      
      //     }
      //   }
      //   runGet()
      // }, [collectors, submissions]);
     

    

  return <div className='container'>
      <div class="row row-cols-1 row-cols-md-3 g-4">

      <ul class="list-group list-group-flush">

          {Object.keys(avgVar).map((variable)=>(<li key={variable} class="list-group-item">{variable} : {avgVar[variable]}</li>
))}

</ul>
</div>

<div class="btn-group" role="group" aria-label="Basic outlined example">
  <button type="button" onClick={()=>setSelectedGraph('Matrix')} class="btn btn-outline-primary">Matrix</button>
  <button type="button" onClick={()=>setSelectedGraph('Niveles')} class="btn btn-outline-primary">Niveles</button>
  <button type="button" onClick={()=>setSelectedGraph('Pilares')} class="btn btn-outline-primary">Pilares</button>
</div>


<GraphSwitch {...props} surveys={surveys} selectedGraph = {selectedGraph}/>
</div>


};


const GraphSwitch = (props) => {
  switch (props.selectedGraph){
    case 'Matrix': return <MainMatrix {...props}/>
    case 'Niveles': return <MainNiveles {...props}/>
    case 'Pilares': return   <MainPilares {...props}/>



    default:  return <MainMatrix {...props} />
}




}


export default Survey;
