
import React, { useState, useEffect } from 'react';

const VariableWeight = (props) => {

    async function updateVariableWeight(variable, weight){
        var tempVariablesUsed = props.variablesUsed
        tempVariablesUsed[variable] = parseFloat(weight)
        let sum = 0;
          
          for (let prop in tempVariablesUsed) {
            sum += tempVariablesUsed[prop];
          }

        props.setTotalVariableWeight(sum)
        props.setVariablesUsed(tempVariablesUsed)

       
          
          

    }

    return(
        <div>

            {Object.keys(props.variablesUsed).map((thisVariable)=>(
                <div>{thisVariable} : <input className="form-control" type='number' onChange={(e)=>updateVariableWeight(thisVariable,e.target.value)}/></div>
            ))}
        </div>
    )
}

export default VariableWeight