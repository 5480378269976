import React, { useState, useEffect } from 'react';
import {Link } from "react-router-dom";

const Surveys = (props) => {
    const [surveys, setSurveys] = useState([])
    //Get surveys
    async function getSurveys() {
        return fetch(props.serverAddress+'/admin/surveys', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'token' :props.token
          }
        })
          .then(data => data.json())
       }
    //Getting inital
    useEffect(() => {
        async function runGet() {
            const dataFromServer = await getSurveys();
            if(dataFromServer.status == 200){
                setSurveys(dataFromServer.data);
        
            }
        }
       runGet()
        
      }, [ ]);

  return <div className='container'>
      <div class="row row-cols-1 row-cols-md-3 g-4">

   
     
   {surveys.map((survey)=>(<Survey survey={survey} surveys={surveys}/>))}

  
</div>

  </div>;
};
const Survey = (props) => {
  
    return <div>
<div class="col">
    <div class="card h-100">
      <div class="card-body">
        <h5 class="card-title">{props.survey.name}</h5>
        <p class="card-text">{props.survey.desc}</p>
      </div>
      <div class="card-footer">
      <Link style={{ textDecoration: 'none' }} to={"/survey/"+props.survey.id}>
        <a type="button" class="btn btn-outline-primary">
            Select
            </a>  
            </Link>    
      </div>
    </div>
  </div>
    </div>
}


export default Surveys;
