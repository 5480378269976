import React from 'react'
import {Link } from "react-router-dom";

const Home = ({logo}) => {
    return (
        <div class="px-4 py-5 my-5 text-center">
          <img src={logo} height="120px" width="auto"/>
    <h1 class="display-5 fw-bold">Generacion EPI</h1>
    <div class="col-lg-6 mx-auto">
      <p class="lead mb-4">Powered by Evaluable</p>
      <div class="d-grid gap-2 d-sm-flex justify-content-sm-center">
      <Link  style={{ textDecoration: 'none' }} to={"/surveys"}>
        <a type="button" class="btn btn-primary btn-lg px-4 gap-3">
        Get Started

            </a></Link>
      </div>
    </div>
  </div>
    )
}

export default Home
