import {create,all, notDependencies, compositionDependencies} from "mathjs"

const config = {
}

const math = create(all,config)


async function getSubmissions(survey,collector,serverAddress,token) {
    return fetch(serverAddress+'/admin/submissions', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'token' : token
     
      },
      body: JSON.stringify({
        'survey' : survey,
        'collector' : collector
      })
    })
      .then(data => data.json())
   }
   async function getSurvey(serverAddress, surveyId) {
    return fetch(serverAddress+'/survey/get', {
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        'surveyid' : surveyId
     
      },
      
    })
      .then(data => data.json())
   }
   


export const getStakeholderData = async function(requestedData, serverAddress, token){

    // Go through the objec that is passed, retrieve the data from each survey.
    //Ex: 
    // [{
    //     survey : "surveyID",
    //     collector : "collectorID" (or none),
    //     weight : "for now"
    // }
    // ]
    var returningObject = []
    for (var i = 0;i <requestedData.length;i++){
        var thisRequest = requestedData[i]
        
        console.log("Getting stakeholder: ",i)
        var submissionsForStakeholder = await getSubmissions(thisRequest.survey,thisRequest.collector, serverAddress, token)
        const {max, min} = await getVariableMax(thisRequest.survey,serverAddress)

        requestedData[i].max = max
        requestedData[i].min = min

        if(submissionsForStakeholder.status == 200){
            requestedData[i].data = submissionsForStakeholder.data

        }

        //Get avg of variables
        //Get punishment of variables
        //Apply punishment to avg



    }
    return requestedData


}


export const getVariablesForStakeHolders= async function(stakeHolders){
    console.log("We have ", stakeHolders.length, ' stakeholders')
    
    for(var i = 0;i<stakeHolders.length;i++){
        const {variables, appeared} = await getVariableScoresIfAnswered(stakeHolders[i].data)
        stakeHolders[i].variables = variables
        stakeHolders[i].appeared = appeared


    }
    return stakeHolders
}
export const weightedAverage = async function(requestedData){

    // Return a weighted average
    //Ex: 
    // [{
    //     data : 40,
    //     weight : .2
    // }
    // ]
    var theResult = 0
    var sumOfPercentages = 0
    for (var i = 0;i <requestedData.length;i++){
        var thisNumber = requestedData[i].data * requestedData[i].weight
        theResult += thisNumber
        sumOfPercentages +=requestedData[i].weight

    }

    return theResult/sumOfPercentages


}

export const getVariableScoresIfAnswered = async function(submissions) {
    console.log("there are ", submissions.length, " submissions in this stakeholder")
    //Now this is where we do the math
    var questions = {}

    for(var i =0;i<submissions.length;i++){

        //For each submission
        var thisSubmission = submissions[i]
        if(thisSubmission.userInputs){
            console.log("THIS SUMMISIONS REPORT", thisSubmission.results)
            for(var x=0;x<thisSubmission.userInputs.length;x++){
                //If the question is answered

                if(thisSubmission.userInputs[x].answered && thisSubmission.userInputs[x].variablesAffected && thisSubmission.userInputs[x].variablesAffected.length > 0){
                    //If the question has already been recorded
                    if(questions[thisSubmission.userInputs[x].id]){
                        questions[thisSubmission.userInputs[x].id].push(thisSubmission.userInputs[x])
                    }else{
                        questions[thisSubmission.userInputs[x].id] = [thisSubmission.userInputs[x]]
                    }
                   
                }
                
            }
        }
        
    }
    console.log("How many answered questions?",Object.keys(questions).length)
    var variablesCount = {}
    //For each question, we go through all the submissions. And come up with an average for each variable affected
    ///////For each key in questions
    //Then add that to a running total of the variables

    var variableAppear = {}
    for(var allQ = 0; allQ < Object.keys(questions).length;allQ++){
        console.log("ROUND", allQ, Object.assign({}, variablesCount))
        var variableAppearTemp = {}

        var thisQuestion = questions[Object.keys(questions)[allQ]]
        var thisQVariableCount = {}
        //All the user inputs in this question
        for(var i = 0;i<thisQuestion.length;i++){
            console.log("this question", thisQuestion)

            //All the variables affected by the user input
            for(var q = 0; q<thisQuestion[i].variablesAffected.length;q++){
                var currentVariableAffected = thisQuestion[i].variablesAffected[q]
                if(thisQVariableCount[currentVariableAffected.variable]){
                    
                    thisQVariableCount[currentVariableAffected.variable] += parseFloat(currentVariableAffected.weight)
                }else{
                    thisQVariableCount[currentVariableAffected.variable] = parseFloat(currentVariableAffected.weight)
                  
                }
                if(variableAppear[currentVariableAffected.variable]){
                    
                    variableAppear[currentVariableAffected.variable] += 1/thisQuestion.length
                }else{
                    variableAppear[currentVariableAffected.variable] = 1/thisQuestion.length
                  
                }
            }
        }


        //For each variable afffected by this question
        for(var i = 0;i<Object.keys(thisQVariableCount).length;i++){
            
            thisQVariableCount[Object.keys(thisQVariableCount)[i]] /= thisQuestion.length
            if(variablesCount[Object.keys(thisQVariableCount)[i]]){
                
                variablesCount[Object.keys(thisQVariableCount)[i]] += thisQVariableCount[Object.keys(thisQVariableCount)[i]]
            }else{
                variablesCount[Object.keys(thisQVariableCount)[i]] = thisQVariableCount[Object.keys(thisQVariableCount)[i]]


            }
        }
        

    }
    console.log("Final outpiut",variablesCount, variableAppear)
    return({variables: variablesCount, appeared : variableAppear})

}
const getVariableMax = async function(surveyId, serverAddress) {
    var survey = await getSurvey(serverAddress, surveyId)
    console.log("GetTING VARIBALE MAX OF", survey)

    var questions = survey.data.questions

    console.log("Questions count", questions.length)
    //Go through each question for this survey, find the maximum for each
    var maxes = []
    var mins = []

    for(var i = 0; i<questions.length;i++){
        var allOptions = questions[i].options
        maxes[i] = {}
        mins[i] = {}
        for(var x= 0;x<allOptions.length;x++){
            var thisOption = allOptions[x]
            //Check which type of question we have
            // Where each option is different
            var whichVar
            if(thisOption.variables && thisOption.variables.length> 0){
                whichVar  = thisOption
            }else{
                
                whichVar  = questions[i]

            }
            for(var y= 0;y<whichVar.variables.length;y++){
                var thisVariable = whichVar.variables[y]
                //If we have gone over this variable in this question before
                if(maxes[i][thisVariable.variable]){
                    //If our the one in there is larger than what we are on right now
                    if((parseFloat(thisVariable.weight) * parseFloat(thisOption.value)) > maxes[i][thisVariable.variable]){

                        maxes[i][thisVariable.variable] = parseFloat(thisVariable.weight) * parseFloat(thisOption.value)
                    }


                }else{
                        maxes[i][thisVariable.variable] = parseFloat(thisVariable.weight) * parseFloat(thisOption.value)

                    
                }
                //Mins
                if(mins[i][thisVariable.variable]){
                    //If our the one in there is larger than what we are on right now
                    if((parseFloat(thisVariable.weight) * parseFloat(thisOption.value)) < mins[i][thisVariable.variable]){
                        console.log("Replacing",mins[i][thisVariable.variable],"with", parseFloat(thisVariable.weight) * parseFloat(thisOption.value))
                        mins[i][thisVariable.variable] = parseFloat(thisVariable.weight) * parseFloat(thisOption.value)
                    }


                }else{
                        mins[i][thisVariable.variable] = parseFloat(thisVariable.weight) * parseFloat(thisOption.value)

                    
                }
               
            
            }  


     

            
        }

    }
    console.log("MAXES",maxes)
    console.log("MINS",mins)

    //Now we calculate the max variables combined for each question
    var maxVariables = {}
    for(var i = 0; i<maxes.length;i++){
        var varThisQ = Object.keys(maxes[i])
        for(var x = 0; x<varThisQ.length;x++){
            var thisVariable = maxes[i][varThisQ[x]]

            if(maxVariables[varThisQ[x]]){
                maxVariables[varThisQ[x]] += thisVariable
            }else{
                maxVariables[varThisQ[x]] = thisVariable

            }
        }

    }
    //Mins
    var minVariable = {}
    for(var i = 0; i<mins.length;i++){
        var varThisQ = Object.keys(mins[i])
        for(var x = 0; x<varThisQ.length;x++){
            var thisVariable = mins[i][varThisQ[x]]

            if(minVariable[varThisQ[x]]){
                minVariable[varThisQ[x]] += thisVariable
            }else{
                minVariable[varThisQ[x]] = thisVariable

            }
        }

    }
    console.log("ALLMAX", maxVariables)
    console.log("ALLMIN", minVariable)
    return {max : maxVariables, min : minVariable}

    



    

}

export const getQuestionAnswers = async function(submissions) {
    var questions = {}

    for(var i = 0;i<submissions.length;i++){
        var thisSubmission = submissions[i]
        var userInputs= thisSubmission.userInputs
        //Loop through every question answered or unanswered
        for(var j =0;j<userInputs.length;j++){
            var thisQuestion = userInputs[j]
            //If this question is compatable and has been answered
            if(thisQuestion.answered && thisQuestion.value){
                //If this question has been answered before
                if(questions[thisQuestion.id]){
                    //Add value
                    questions[thisQuestion.id].push(thisQuestion.value)

                }else{
                    //If question has not been answered before
                    questions[thisQuestion.id] = [thisQuestion.value]
                    
                }
            

                

            }


        }
    
        
    }
    
  

    return questions


    

}

export const getPen =  async function(questions, pen1, pen2, pen3, pen4) {

    var endScore = questions
    var qList = Object.keys(questions)

    for(var i = 0; i<qList.length;i++){
        var thisQ = questions[qList[i]]
        console.log("Still getting one", thisQ)


        var theCV = math.std(thisQ)/math.mean(thisQ)
        console.log("THE CV", theCV)
        var penalization 
        switch (true) {
            case theCV<= .3:
                penalization = pen1
                break;
            case theCV <= .5:
                penalization = pen2
                break;
            case theCV <= .8:
                penalization = pen3
                break;
            case theCV <= .99:
                penalization = pen4
                break;
            case
                theCV > .99:
                penalization = pen4
                break;



        }
        let mean = questions[qList[i]].reduce((acc, curr)=>{
            return acc + curr
          }, 0) / questions[qList[i]].length;

        endScore[qList[i]] = penalization

        

    }



    return(endScore)

}

export const CV = function(arr){
    // Creating the mean with Array.reduce
    let mean = arr.reduce((acc, curr)=>{
      return acc + curr
    }, 0) / arr.length;
     
    // Assigning (value - mean) ^ 2 to every array item
    arr = arr.map((k)=>{
      return (k - mean) ** 2
    })
     
    // Calculating the sum of updated array
   let sum = arr.reduce((acc, curr)=> acc + curr, 0);
    
   // Calculating the variance
   let variance = sum / arr.length
  
   // Returning the Standered deviation
   return Math.sqrt(sum / arr.length) / mean
  }
