import Graph from './Graph'
import GetStakeholders from '../../Stakeholder/GetStakeholders'
import React, { useState, useEffect } from 'react';
import { getStakeholderData, getVariableScoresIfAnswered, getVariablesForStakeHolders } from '../../Tools';
import Bar from "../Nivo/Bar"
import VariableWeight from './VariableWeight';
const MainNiveles = (props) => {
    const [stakeHolders, setStakeHolders] = useState([])
    const [updateStakeHolders, setUpdateStakeHolders] = useState(0)
    const [variablesUsed, setVariablesUsed] = useState({'E':1,'P':1,'I':1})
    const [totalVariableWeight, setTotalVariableWeight] = useState(1)

    const [onlyCollector, setOnlyCollector] = useState([])

    const [data, setData] = useState([  {
        "variable": "AD",
        "hot dog": 98,
        "hot dogColor": "hsl(239, 70%, 50%)",
        "burger": 117,
        "burgerColor": "hsl(103, 70%, 50%)",
        "sandwich": 69,
        "sandwichColor": "hsl(313, 70%, 50%)",
        "kebab": 49,
        "kebabColor": "hsl(68, 70%, 50%)",
        "fries": 41,
        "friesColor": "hsl(82, 70%, 50%)",
        "donut": 161,
        "donutColor": "hsl(52, 70%, 50%)"
      }, {
        "variable": "Ass",
        "hot dog": 98,
        "hot dogColor": "hsl(239, 70%, 50%)",
        "burger": 117,
        "burgerColor": "hsl(103, 70%, 50%)",
        "sandwich": 69,
        "sandwichColor": "hsl(313, 70%, 50%)",
        "kebab": 49,
        "kebabColor": "hsl(68, 70%, 50%)",
        "fries": 41,
        "friesColor": "hsl(82, 70%, 50%)",
        "donut": 161,
        "donutColor": "hsl(52, 70%, 50%)"
      }])

    const variables = ['Cultura Empoderadora','Clima Positivo','Cultura Innovadora']
    

    useEffect(() => {
       console.log("Niveles stakeholders are being updated", stakeHolders)
       var temp = []
       for(var i=0;i<stakeHolders.length;i++){
           temp.push(stakeHolders[i].collectorName)
       }
       setOnlyCollector(temp)
       
        
      }, [ stakeHolders, updateStakeHolders]);

      //This is when the user click
      async function generateNiveles(){
          console.log("Generating Niveles")


          //Returns the stakeHolder array + a data attribute with all the submissions
          var allData = await getStakeholderData(stakeHolders, props.serverAddress, props.token)


          var finalData = await getVariablesForStakeHolders(allData)
          console.log("Final Data Niveles", finalData)


          //For each stakeholder, loop throu          
          var processedData = [{
              variable : 'E'},
            {variable : 'P'} ,
            {variable : 'I'}        ]
           
          var puntajeEPI = {variable : "Puntaje EPI"}
          for(var i =0;i<finalData.length;i++){
           


              //First we add all the variables that are already in the list
              for(var y =0;y<processedData.length;y++){
                if(finalData[i].variables[processedData[y].variable]){

                  var variableMax = finalData[i].max[processedData[y].variable]
                  var variableMin = finalData[i].min[processedData[y].variable]

                  console.log("VARIABLE MIN",variableMin)
                    processedData[y][finalData[i].collectorName] = (finalData[i].variables[processedData[y].variable]-variableMin)/(variableMax-variableMin)
                    

                    if(puntajeEPI[finalData[i].collectorName]){
                       puntajeEPI[finalData[i].collectorName] += (variablesUsed[processedData[y].variable] * (finalData[i].variables[processedData[y].variable]-variableMin)/(variableMax-variableMin))/totalVariableWeight

                    }else{
                      puntajeEPI[finalData[i].collectorName] = (variablesUsed[processedData[y].variable] * (finalData[i].variables[processedData[y].variable]-variableMin)/(variableMax-variableMin))/totalVariableWeight
                    }
                    //WEIGHT FOR processedData[y].variable

                    /*

                    Puntaje EPI is as its own variable, yet the its weighted per stakeholder?
                    */

                }
                }

          }
          processedData.push(puntajeEPI)

        


          console.log("Final data niveles", processedData)
          setData(processedData)
          /* 
          variables we are using
          */
        

      }
    
  return (
<div className='text-center'>


    <GetStakeholders {...props} 
    stakeHolders = {stakeHolders} 
    setStakeHolders={setStakeHolders}
    updateStakeHolders={updateStakeHolders}
    setUpdateStakeHolders={setUpdateStakeHolders}
    />
    <VariableWeight variablesUsed={variablesUsed} setVariablesUsed={setVariablesUsed} setTotalVariableWeight={setTotalVariableWeight}/>
    <a className='btn btn-success' onClick={()=>{generateNiveles()}}>Generate Niveles</a>

    <br />

    <Bar data={data} indexBy={'variable'} keys={onlyCollector}/>
    </div>





  )
}

export default MainNiveles