import React, { useState, useEffect } from 'react';

const GetStakeholders = (props) => {


  return (
    <div>

        <h3>Choose stakeholders</h3>
        <table class="table">
  <thead>
    <tr>
      <th scope="col">#</th>
      <th scope="col">Survey</th>
      <th scope="col">Collector</th>
      <th scope="col">Weight</th>
    </tr>
  </thead>
  <tbody>
  {props.stakeHolders.map((stakeholder, index)=>(<Stakeholder stakeHolder={stakeholder} {...props} index={index} key={index}/>))}

   
  </tbody>
</table>

        <br />
        <a className='btn btn-primary' onClick={()=>{props.setStakeHolders([...props.stakeHolders,{surveyName : undefined, collectorName : undefined}])}}>Add new stakeholder</a>
    </div>
  )
}

const Stakeholder = (props)=>{
    const [selectedSurvey, setSelectedSurvey] = useState({collectors:[]})
    const [survey, setSurvey] = useState('')
    const [collector, setCollector] = useState('')

    const [weight, setWeight] = useState(0)



    useEffect(() => {
        console.log("Running the update")
        var tempAll = props.stakeHolders
        var collectorName = ''
        for(var i =0;i<selectedSurvey.collectors.length;i++){
            if(selectedSurvey.collectors[i].id == collector){
                collectorName = selectedSurvey.collectors[i].name

            }
        }

        //then update the one with our index
        tempAll[props.index] = {survey: survey, collector : collector, weight : weight, collectorName : collectorName}

        props.setStakeHolders(tempAll)
        props.setUpdateStakeHolders(1+props.updateStakeHolders)
        
        
      }, [ survey, collector, weight]);

    function setNewSurvey(surveyId){
        for(var i =0;i<props.surveys.length;i++){
            if(props.surveys[i].id == surveyId){
                setSelectedSurvey(props.surveys[i])
            }
        }
    }




    return(
             <tr>
      <th scope="row">{props.index}</th>
      <td> <select class="form-select" aria-label="Default select example" onChange={(e)=>{setNewSurvey(e.target.value); setSurvey(e.target.value)}}>
                <option selected>Choose Survey</option>
                {props.surveys.map((survey)=>(<option value={survey.id}>{survey.name}</option>))}

                </select></td>

      <td> <select class="form-select" aria-label="Default select example" onChange={(e)=>{setCollector(e.target.value)}}>
                <option selected>Choose Collector</option>

                    {selectedSurvey.collectors.map((collector)=>(<option value={collector.id}>{collector.name}</option>))}
                </select></td>
      <td>                <input type='number' class="form-control"  placeholder="Weight" onChange={(e)=>{setWeight(e.target.value)}}/>
</td>
    </tr>
           
        

    )
}
export default GetStakeholders